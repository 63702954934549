<template>
	<q-card style="width: 700px; max-width: 80vw;">
		<q-card-section class="text-center">
			<span class="text-h5">Additional Information for {{additional_info['webhook_name']}}</span>
		</q-card-section>
		<q-card-section>
			<div
				v-for="(value, key) in additional_info"
				:key="value"
			>
				<q-item
					class="row file-header"
					v-if="['message_content', 'session_name', 'failure_reason'].includes(key) && value"
				>
					<q-item-section class="col-4" tag="label">
						<q-item-label class="q-ml-md">
							<b>{{ key.charAt(0).toUpperCase() + key.slice(1).replace("_", " ") }}</b>
						</q-item-label>
					</q-item-section>
					<q-item-section class="col-8">
						<q-item-label
							class="q-ml-md"
							style="max-height: 700px;overflow-y: auto;line-break: anywhere;"
						>
							{{ value }}
						</q-item-label>
					</q-item-section>
				</q-item>
			</div>
	</q-card-section>
		<q-card-actions align="right">
			<q-btn flat label="Close" color="primary" @click="$emit('close')" />
		</q-card-actions>
	</q-card>
</template>
<script>
export default {
	name: 'AdditionalInfoDialog',
	props: {
		additional_info: Object
	},
}
</script>
<style lang="scss" scoped>
.file-header {
	font-size: 15px;
	border-bottom: 3px double $grey-5
}
</style>