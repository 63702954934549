import axios from 'axios';

export default {
    async createTeamsWebhook(cg_id, data){
		try {
			const response = await axios.post(`/api/microsoft_teams/webhook/${cg_id}/`, data);
			return response.data;
		} catch (err) {
			return {
				error: true,
				error_message: err.response.data,
			};
		}
	},
	async editTeamsWebhook(cg_id, webhook_id, data){
		try {
			const response = await axios.post(`/api/microsoft_teams/webhook/${cg_id}/${webhook_id}/`, data);
			return response.data;
		} catch (err) {
			return {
				error: true,
				error_message: err.response.data,
			};
		}
	},
	async getMicrosoftTeamsWebhook(cg_id, fetch_all){
		let url = `/api/microsoft_teams/${cg_id}/`
		if (fetch_all) {
			url += '?all=true'
		}
		const response = await axios.get(url);
		return response.data;
	},
	async deleteWebhook(cg_id, webhook_id){
		try {
			const response = await axios.delete(`/api/microsoft_teams/webhook/${cg_id}/${webhook_id}/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getMicrosoftTeamsReports(){
		try {
			const response = await axios.get(`/api/microsoft_teams/list/`);
			return response.data;
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},

	async sendQuickMessage(data){
		try {
			const response = await axios.put('/api/microsoft_teams/webhook/send_quick_message/', data);
			return response.data;
		} catch (err) {
			return {
				error: true,
				error_message: err.response.data,
			};
		}

	}

}
